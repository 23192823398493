import React from 'react'
import { Link, PageProps } from 'gatsby'
import { Lightning } from 'phosphor-react'
import { BasicLayout } from '../../layouts/basic'
import { SEOMeta } from '../../components/SEOMeta'
import { JiraSignupBar } from '../../components/auth/JiraSignupBar'
import jiraIcon from '../../images/jira-icon.svg'
import jiraLiveTyping from '../../images/jira-live-typing.png'
import jiraTag from '../../images/jira-tag.svg'
import jiraSticky from '../../images/jira-sticky.svg'
import jiraPoker from '../../images/jira-poker.svg'
import jiraHotspot from '../../images/jira-hotspot.svg'

const Jira: React.FC<PageProps> = ({ location }) => (
  <BasicLayout>
    <SEOMeta
      title="Whiteboard for Jira"
      description="Work with your Jira issues, bugs, stories and epics on a flexible multiplayer whiteboard with two-way sync"
      path={location.pathname}
    />
    <div className="max-w-[720px] mx-auto px-4">
      <header className="mt-8 px-2 text-center">
        <picture className="block mx-auto w-24 h-24 sm:w-32 sm:h-32">
          <img className="w-full h-full" src={jiraIcon} alt="Jira icon" />
        </picture>
        <h1 className="text-text-primary-light mt-4 text-3xl font-extrabold tracking-tight leading-tight sm:text-5xl">
          Jira
        </h1>
        <p className="text-text-primary-light mt-4 mx-auto max-w-xl text-base sm:mt-6 sm:text-lg">
          Connect Jira to Qualdesk to work with your issues and epics on a
          flexible multiplayer whiteboard with two-way syncing.
        </p>
        <a
          className="group bg-background-one-light rounded-[20px] flex items-center mt-8 mx-auto p-1 w-max cursor-pointer sm:mt-10"
          href={process.env.SIGNUP_URL}
        >
          <div className="to-background-five-light from-background-four-light rounded-[16px] w-[90px] h-[48px] flex items-center justify-center bg-gradient-to-t">
            <Lightning
              className="text-icon-secondary-light group-hover:text-teal-400 w-6 h-6"
              weight="fill"
            />
          </div>
          <div className="text-text-secondary-light w-[90px] h-[48px] flex items-center justify-center text-base font-medium tracking-tight leading-tight">
            Connect
          </div>
        </a>
        <p className="text-text-secondary-light mt-4 text-sm">
          Sign up for Qualdesk and connect Jira in under a minute
        </p>
      </header>
      <figure className="bg-orange-100 md:max-h-[348px] relative flex flex-col-reverse justify-between mb-4 mt-12 rounded-xl overflow-hidden sm:mt-14">
        <div className="ring-orange-800 ring-opacity-[16%] absolute inset-0 rounded-xl ring-2 ring-inset" />
        <img
          className="max-w-[476px] max-h-[348px] self-end w-full"
          src={jiraLiveTyping}
          alt="Realtime cursors and and live typing on a Jira issue"
        />
        <figcaption className="text-orange-800 md:max-w-[427px] z-10 left-0 top-0 pt-8 px-8 text-2xl font-medium tracking-tight leading-tight sm:text-3xl md:absolute">
          Work with Jira bugs, stories, tasks and epics in real time on a visual
          whiteboard
        </figcaption>
      </figure>
      <div className="my-4 sm:grid sm:gap-6 sm:grid-cols-2 sm:my-8">
        <figure className="min-h-[249px] relative flex flex-col-reverse justify-between my-4 bg-indigo-100 rounded-xl overflow-hidden sm:my-0">
          <div className="ring-opacity-[16%] absolute inset-0 rounded-xl ring-indigo-800 ring-2 ring-inset" />
          <img
            className="max-w-[64px] max-h-[64px] absolute bottom-8 right-8"
            src={jiraTag}
            alt="Update issue status, assignees and labels directly from the
            whiteboard"
          />
          <figcaption className="absolute left-0 top-0 p-8 text-indigo-800 text-2xl font-medium tracking-tight leading-tight">
            Update issue status, assignees and labels directly from the
            whiteboard
          </figcaption>
        </figure>
        <figure className="relative flex flex-col-reverse row-span-2 justify-between my-4 bg-red-100 rounded-xl overflow-hidden sm:my-0">
          <div className="ring-opacity-[16%] absolute inset-0 rounded-xl ring-red-800 ring-2 ring-inset" />
          <img
            className="max-w-[348px] max-h-[348px] self-end w-full"
            src={jiraPoker}
            alt="Run planning poker sessions and vote on estimates"
          />
          <figcaption className="p-8 text-red-800 text-2xl font-medium tracking-tight leading-tight sm:text-3xl">
            Run planning poker sessions and vote on estimates
          </figcaption>
        </figure>
        <figure className="bg-amber-100 min-h-[249px] relative flex flex-col-reverse justify-between my-4 rounded-xl overflow-hidden sm:my-0">
          <div className="ring-amber-800 ring-opacity-[16%] absolute inset-0 rounded-xl ring-2 ring-inset" />
          <img
            className="max-w-[64px] max-h-[64px] absolute bottom-8 right-8"
            src={jiraSticky}
            alt="Transform sticky notes into Jira issues and epics"
          />
          <figcaption className="text-amber-800 absolute left-0 top-0 p-8 text-2xl font-medium tracking-tight leading-tight">
            Transform sticky notes into Jira issues and epics
          </figcaption>
        </figure>
      </div>
      <JiraSignupBar />
      <figure className="min-h-[348px] relative flex flex-col-reverse justify-between my-4 bg-blue-100 rounded-xl overflow-hidden sm:mt-8">
        <div className="ring-opacity-[16%] absolute z-10 inset-0 rounded-xl ring-blue-800 ring-2 ring-inset" />
        <img
          className="max-w-[440px] max-h-[393px] absolute bottom-0 right-0 w-full"
          src={jiraHotspot}
          alt="Create hotspots to automate Jira actions"
        />
        <figcaption className="max-w-[200px] md:max-w-[427px] absolute z-10 left-0 top-0 pt-8 px-8 text-blue-800 text-2xl font-medium tracking-tight leading-tight sm:text-3xl">
          Create hotspots to automate Jira actions
        </figcaption>
      </figure>
      <footer className="mt-8">
        <Link
          className="border-border-normal-light active:bg-button-secondary-active-light hover:bg-background-three-light text-text-primary-light block px-8 py-4 text-center text-base font-medium tracking-tight leading-tight border rounded-lg"
          to="/integrations"
        >
          View all integrations
        </Link>
      </footer>
    </div>
  </BasicLayout>
)

export default Jira
