import React from 'react'
import jiraIconWhite from '../../images/jira-icon-white.svg'
import { SignupBar } from './SignupBar'

export const JiraSignupBar: React.FC = () => (
  <SignupBar
    title="Jira"
    description="Sign up and connect in under a minute"
    iconSrc={jiraIconWhite}
    url={process.env.SIGNUP_URL}
  />
)
